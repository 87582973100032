/* You can add global styles to this file, and also import other style files */
@import '~material-icons/iconfont/material-icons.scss';
@import '~bootstrap/dist/css/bootstrap.min.css';

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.icon-label {
  font-size: 20px;
}

.user-icon {
  font-size: 2.5em;
  color: #205c40;
}

.card-users {
  margin-bottom: 10px;
}

.selected {
  background-color: #205c40;
  color: white;
}

.uploader-status ::ng-deep .mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #205c40;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #205c40;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #205c40;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(32 92 64 / 54%);
}

.mat-badge-content {
  background: #205c40;
  color: #fff;
}
.required:after {
  content: ' *';
  color: red;
}

.mat-slider-thumb {
  background-color: #205c40 !important;
}

.mat-slider-thumb-label {
  background-color: #205c40 !important;
}

.mat-slider-track-fill {
  background-color: #205c40 !important;
}

.sync-progress-bar .mat-progress-bar-fill::after {
  background-color: rgb(92, 184, 92) !important;
  color: white !important;
}

.mat-progress-bar-fill::after {
  background-color: #205c40;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #205c40 !important;
  color: #fff;
  font-size: 1em;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: #595959;
  font-size: 1.2em !important;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.full-medium-width {
  width: 100%;
}

.button-filter {
  color: #205c40;
}

.mat-mini-fab.mat-primary {
  background-color: #205c40;
  margin-top: 5px;
}

.expanded-action-buttons {
  padding-bottom: 20px;
}

.expanded-headers-align .mat-expansion-panel-header-title,
.expanded-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
  margin-top: 10px;
}

.expanded-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.expanded-headers-align .mat-form-field + .mat-form-field {
  margin-left: 8px;
}
.text_delete_side_nav {
  font-size: 0.7rem;
  font-weight: bold;
}

.input-box.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.input-chip .cdk-drop-list-dragging {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-chip-list {
  background-color: #fff;
}

.mat-chip.mat-standard-chip {
  background-color: #278b5d;
  color: #fff;
  font-size: 12x !important;
}
.input-full-width {
  width: 100%;
}

.input-full-width-filter {
  width: 100%;
  background-color: none;
  border: none;
}

#dropZone {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.next-button {
  background-color: var(--strong-green);
  border-radius: 100px;
  color: #fff;
  padding: 5px 20px 5px 20px;
  font-size: 16px;
}

.back-button {
  border-radius: 100px;
  border: 1px solid #205c40;
  color: #205c40;
  padding: 3px 18px 3px 18px;
  font-size: 16px;
}

.camera-label {
  background-color: #f2f2f2;
  border-radius: 100px;
  color: #205c40;
  padding: 5px 20px 5px 20px;
  font-size: 18px;
}

.load-file-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.blink {
  animation: blinker 4s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

.poll-popup-alert-position {
  z-index: 999999 !important;
}

.client-all-icon {
  color: #205c40;
}
.client-all-icon-account_circle {
  margin-top: 5px;
}

.client-icon {
  font-size: 2em;
  color: #205c40;
  margin-bottom: 8px;
  padding: 0;
  position: relative;
  left: -4px;
}
/* Accordion */

.accordion-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}
.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top: 2px;
}
.accordion-headers-align .mat-form-field + .mat-form-field {
  margin-left: 8px;
}

/* scroll button */
.scrollToTopBtn {
  position: fixed;
  bottom: 70px;
  right: 30px;
  z-index: 3;
  background-color: #205c40;
  border: 1px #205c40;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 48px;
  width: 48px;

  cursor: pointer;
  transition: all 0.6s ease-in-out;
}

.scrollToTopBtn :hover {
  background-color: #8c43e6;
}

.text-offline {
  color: #205c40;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  width: 80px;
}
.text-small-bold {
  font-weight: bold;
  font-size: 12px;
}

.content-select-route .mat-list-text {
  align-items: start;
  text-align: left;
}

.container-drawer {
  height: 100%;
}

.content-poll .mat-tab-body-wrapper,
.content-poll .mat-tab-group {
  height: 100%;
}

.mat-tab-body-content,
.mat-tab-body.mat-tab-body-active {
  overflow: hidden !important;
}

.container-drawer .mat-drawer-inner-container {
  /* overflow: hidden; */
}

.container-drawer .mat-form-field-wrapper {
  padding-bottom: 10px;
}

.container-drawer .mat-form-field-underline {
  bottom: 10px;
}

.btn-option {
  color: #205c40;
  background-color: transparent;
  min-width: 10px;
}

.btn-option.active {
  color: #0096d6;
}

mat-sidenav-container {
  height: calc(100vh - 65px);
}

.custom-dialog-container .mat-dialog-container {
  /* añade tus estilos */
  max-width: none;
  padding: 0;
  background: #278b5d;
}

@media (max-width: 320px) {
  .mat-chip.mat-standard-chip {
    background-color: #278b5d;
    color: #fff;
    font-size: 12x !important;
  }
}


/* NUEVOS DISEÑOS GLOBALES */
:root {
  --gradient-superior-green: #23402e;
  --gradient-inferior-green: #294634;

  --font-gray: #9c9c9c;

  --strong-green: #122e1e;
  --medium-green: #294634;
  --light-green: #516750;
  --soft-green: #75bb43;

  --transparent-bg: #00000000;
}

.global-bg-gradient {
  background: rgb(35,64,46);
  background: linear-gradient(180deg, var(--gradient-superior-green) 75%, var(--gradient-inferior-green) 85%);
  color: white;
}

.global-bg-strong-green {
  background-color: var(--strong-green);
}

.global-bg-light-green {
  background-color: var(--light-green);
}

.global-bg-soft-green {
  background-color: var(--soft-green);
}

